export const Footer = () => {
return(
<footer className="footer">
    <div className="footer-columns">
        <div className="footer-info">
            <div className="d-flex flex-column ">
            <img
                width="190"
                height="80"
                className="d-inline-block align-top footer-logo"
                src="/images/holo.svg"
                alt="Hololoot logo"
            />
            <span className="footer-description ">Hololoot lets you trade and interact with AR-enabled NFTs directly through your phone. Try it today!</span>
            <div className="d-flex my-3 footer-socials">
                <a target="_blank" rel="noreferrer" href={"https://linkedin.com/"}>
                    <img src="/images/social.svg" alt="Linkedin"/>
                </a>
                <a target="_blank" rel="noreferrer" href={"https://www.youtube.com/c/Hololoot"}>
                    <img src="/images/youtube.svg" alt="Youtube"/>
                </a>
                <a target="_blank" rel="noreferrer" href={"https://twitter.com/hololoot"}>
                    <img src="/images/twitter.svg" alt="Twitter" /> 
                </a>
                <a target="_blank" rel="noreferrer" href={"https://www.instagram.com/hololoot/"}>
                    <img src="/images/instagram.svg" alt="Instagram"/>
                </a>
            </div>
        </div>
        </div>
        <div className="footer-links-column">
            <div className="d-flex flex-column">
                <h2 className="footer-link-header">Company</h2>
                <a className='footer-link' target="_blank" rel="noreferrer" href={"https://hololoot.io/"}>Home</a>
                <a className='footer-link' target="_blank" rel="noreferrer" href={"https://hololoot.io/team/"}>Team</a>
                <a className='footer-link' target="_blank" rel="noreferrer" href={"https://hololoot.io/partnerships/"}>Partnerships</a>
                <a className='footer-link' target="_blank" rel="noreferrer" href={"https://hololoot.io/privacy-policy/"}>Legal Notice</a>
            </div>
        </div>
        <div className="footer-links-column mb-4">
            <div className="d-flex flex-column">
                <h2 className="footer-link-header">Contact</h2>
                <a className='footer-link' target="_blank" rel="noreferrer" href={"https://hololoot.io/press/"}>Press</a>
                <a className='footer-link' target="_blank" rel="noreferrer" href={"https://hololoot.io/blog/"}>Blog</a>
                <a className='footer-link' target="_blank" rel="noreferrer" href={"https://hololoot.io/contact/"}>Contact</a>
            </div>
        </div>
    </div>
    <div>
        <div className="footer-border">Hololoot, ©{(new Date().getFullYear())}</div>
    </div>
</footer>
)}