import { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useInView } from 'react-intersection-observer';
import 'animate.css';
import { SubpageNavbar } from "../components/SubpageNavbar";

export const Artists = () => {
  const { ref: firstRef, inView: ART1 } = useInView({ triggerOnce: true });
  const { ref: secondRef, inView: ART2 } = useInView({ triggerOnce: true });
  const { ref: thirdRef, inView: ART3 } = useInView({ triggerOnce: true });
  const { ref: fourthRef, inView: ART4 } = useInView({ triggerOnce: true });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const bookACall = () => {
    window.open("https://calendly.com/dglowicki/30-minutes-meeting-with-hololoot");
  }

  return (
    <>
      <SubpageNavbar/>
      <div className="artist animate__animated animate__fadeIn">
        <span className="main-text">Artists</span>
        <img className="arrows" src="/images/arrows-main.svg" alt="Arrow"/>
      </div>
      <div ref={firstRef} className={ART1 ? "artist-I is-visible mt-1" : "artist-I fade-in mt-1"}>
        <div className="ART-I-1">
          <h2 className="mb-4">Bring Your Art To Life </h2>
          {windowWidth >= 400 ?
            <p>
              Turn your creations into immersive,<br/>
              interactive experiences for your<br/>
              fans to enjoy. With Hololoot, you<br/>
              can add depth, dimension, and<br/>
              movement to your art in ways you<br/>
              never thought possible.
            </p>
            :
            <p>
              Turn your creations into immersive,
              interactive experiences for your
              fans to enjoy. With Hololoot, you
              can add depth, dimension, and
              movement to your art in ways you
              never thought possible.
            </p>
          }
        </div>
        <div className="ART-I-2">
          <img className="muscle-god" src="/images/art-muscle-god.png" alt="Muscle phone"/>
        </div>
      </div>
      <div ref={secondRef} className={ART2 ? "artist-II is-visible" : "artist-II fade-in"}>
        <div className="ART-II-1">
          <img className="art-mozaik" src="/images/artist-block.png" alt="Location"/>
        </div>
        <div className="ART-II-2">
          <h2 className="mb-4">Elevate Your Artistic Brand</h2>
          {windowWidth >= 385 ?
            <>
              <p className="pb-4">
                Make a lasting impression on your<br/>
                fans and establish yourself as<br/>
                a leader in the art world with<br/>
                Hololoot.
              </p>
              <p>
                Our AR Digital Collectibles offer<br/>
                exclusive and collectible<br/>
                experiences that set your art apart<br/>
                and strengthen your brand<br/>
                recognition.
              </p>
            </> :
            <>
              <p className="pb-4">
                Make a lasting impression on your
                fans and establish yourself as
                a leader in the art world with
                Hololoot.
              </p>
              <p>
                Our AR Digital Collectibles offer
                exclusive and collectible
                experiences that set your art apart
                and strengthen your brand
                recognition.
              </p>
            </>
          }
        </div>
      </div>
      <div ref={thirdRef} className={ART3 ? "artist-III is-visible" : "artist-III fade-in"}>
        <div className="ART-III-1">
          <h2 className="mb-4">Stay Up To Date With Current Trends</h2>
          {windowWidth >= 420 ?
            <>
              <p>
                Our exclusive collectible experiences<br/>
                set your art apart and establish you<br/>
                as a trendsetter.
              </p>
              <p>
                Try Hololoot now and take<br/>
                advantage of our cutting-edge<br/>
                technology to create a unique and<br/>
                memorable experience for your<br/>
                audience.
              </p>
            </> :
            <>
              <p>
                Our exclusive collectible experiences
                set your art apart and establish you
                as a trendsetter.
              </p>
              <p>
                Try Hololoot now and take
                advantage of our cutting-edge
                technology to create a unique and
                memorable experience for your
                audience.
              </p>
            </>
          }
        </div>
        <div className="ART-III-2 d-flex flex-row justify-content-center">
          {windowWidth >= 800 ?
            <video autoPlay muted loop>
              <source src="/images/art-statue-phone.webm" type="video/webm"/>
            </video>
            :
            <div className="text-center">
              <img className="car-img" src="/images/art-statue.png" alt="Statue"/>
            </div>
          }
        </div>
      </div>
      <Container ref={fourthRef} className={ART4 ? "is-visible ctn" : "fade-in ctn"}>
        <Row className="bottom-holo-info gaming-call">
          <Col className="text-center mb-3">
            <img src="/images/holo-wood.svg" alt="LOGO"/>
          </Col>
          <Col className="text-center m-auto mb-5" md={12}>
            <h2 className="mb-4">
              Book A Call And<br/>
              Unleash The Power Of AR In Art
            </h2>
            {windowWidth >= 475 ?
              <p className="mt-3">
                Be sure to transform your art and connect with<br/>
                your fans on a new level. Book a call with our<br/>
                team today and discover the endless<br/>
                possibilities of Hololoot AR marketing.
              </p>
              :
              <p className="mt-3">
                Be sure to transform your art and connect with
                your fans on a new level. Book a call with our
                team today and discover the endless
                possibilities of Hololoot AR marketing.
              </p>
            }
          </Col>
          <Col className="text-center mb-5">
            <button onClick={bookACall} type="button" className="btn btn-wood">Book A Call</button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
