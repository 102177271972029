import { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useInView } from 'react-intersection-observer';
import 'animate.css';
import { SubpageNavbar } from "../components/SubpageNavbar";

export const Influencers = () => {
  const { ref: firstRef, inView: INFLU1 } = useInView({ triggerOnce: true });
  const { ref: secondRef, inView: INFLU2 } = useInView({ triggerOnce: true });
  const { ref: thirdRef, inView: INFLU3 } = useInView({ triggerOnce: true });
  const { ref: fourthRef, inView: INFLU4 } = useInView({ triggerOnce: true });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const bookACall = () => {
    window.open("https://calendly.com/dglowicki/30-minutes-meeting-with-hololoot");
  }
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <SubpageNavbar/>
      <div className="influencer animate__animated animate__fadeIn">
        <span className="main-text">Influencers</span>
        <img className="arrows" src="/images/arrows-main.svg" alt="Arrow"/>
      </div>
      <div ref={firstRef} className={INFLU1 ? "influencer-I is-visible mt-1" : "influencer-I fade-in mt-1"}>
        <div className="INFLU-I-1">
          <h2 className="mb-4">Attract And Engage Your Audience</h2>
          <p className="pb-4">
            As an influencer, your brand is your most
            valuable asset. With Hololoot's services, you
            can take your brand to the next level and
            stand out in a crowded marketplace.
          </p>
          <p>
            Our innovative solution blends AR's power
            with the latest digital marketing technology,
            providing you with a unique and engaging
            way to connect with your audience.
          </p>
        </div>
        <div className="INFLU-I-2">
          <img className="model-jpg" src="/images/influ-model.png" alt="Model"/>
        </div>
      </div>
      <div ref={secondRef} className={INFLU2 ? "influencer-II is-visible" : "influencer-II fade-in"}>
        <div className="INFLU-II-1">
          <img className="helmet-png" src="/images/influ-location-pin.png" alt="Location pin"/>
        </div>
        <div className="INFLU-II-2">
          <h2 className="mb-4">Say Goodbye To Stale Marketing<br/> Techniques</h2>
          {windowWidth >= 430 ?
            <p>
              Experience the future of marketing <br/>
              with Hololoot. Say goodbye to<br/>
              boring photos and videos and hello<br/>
              to captivating and interactive<br/>
              experiences that boost your<br/>
              personal brand. Get ready to<br/>
              engage your audience like never<br/>
              before.
            </p>
            :
            <p>
              Experience the future of marketing
              with Hololoot. Say goodbye to
              boring photos and videos and hello
              to captivating and interactive
              experiences that boost your
              personal brand. Get ready to
              engage your audience like never
              before.
            </p>
          }
        </div>
      </div>
      <div ref={thirdRef} className={INFLU3 ? "influencer-III is-visible" : "influencer-III fade-in"}>
        <div className="INFLU-III-1">
          <h2 className="pb-4">Attract More Followers With<br/> Interactive Campaigns</h2>
          {windowWidth >= 430 ?
            <>
              <p>
                Get ready to excite your followers<br/>
                with Hololoot! Our platform<br/>
                empowers you to create stunning,<br/>
                interactive campaigns tailored to<br/>
                your brand that leave a lasting<br/>
                impression on your audience.
              </p>
              <p>
                Try Hololoot today and take your<br/>
                marketing to the next level!
              </p>
            </>
            :
            <>
              <p>
                Get ready to excite your followers
                with Hololoot! Our platform
                empowers you to create stunning,
                interactive campaigns tailored to
                your brand that leave a lasting
                impression on your audience.
              </p>
              <p> Try
                Hololoot today and take your
                marketing to the next level!</p>
            </>
          }
        </div>
        <div className="INFLU-III-2">
          {windowWidth >= 800 ?
            <div className="viral-holo">
              <video autoPlay muted loop className="holo-logo-ar2">
                <source src="/images/viral-holo.webm" type="video/webm"/>
              </video>
            </div>
            :
            <div className="text-center">
              <img className="car-img" src="/images/influ-phone-girl.png" alt="Girl in phone"/>
            </div>
          }
        </div>
      </div>
      <Container ref={fourthRef} className={INFLU4 ? "is-visible" : "fade-in"}>
        <Row className="bottom-holo-info gaming-call m-0">
          <Col className="text-center mb-3">
            <img src="/images/holo-turquoise.svg" alt="LOGO"/>
          </Col>
          <Col className="text-center m-auto mb-5" md={12}>
            <h2 className="pb-4">
              Book A Call And<br/>
              Take Your Influencer Brand <br/>
              To The Next Level
            </h2>
            {windowWidth >= 470 ?
              <>
                <p className="pb-4 m-auto">
                  Transform your influencer brand with the<br/>
                  power of AR! Our expert team is dedicated to<br/>
                  helping you achieve your marketing goals and<br/>
                  grow your brand.
                </p>
                <p>
                  Book a call with Hololoot today to discover<br/>
                  how we can help you reach new heights.
                </p>
              </> :
              <>
                <p className="pb-4 m-auto">
                  Transform your influencer brand with the
                  power of AR! Our expert team is dedicated to
                  helping you achieve your marketing goals and
                  grow your brand.
                </p>
                <p>
                  Book a call with Hololoot today to discover
                  how we can help you reach new heights.
                </p>
              </>}
          </Col>
          <Col className="text-center mb-5">
            <button onClick={bookACall} type="button" className="btn btn-turquoise">Book A Call</button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
