import { Row, Col, Container, ListGroup } from "react-bootstrap"
import { useState } from "react";
import 'animate.css'
import { useInView } from 'react-intersection-observer';


export const chooseImage = [
  { src: "/images/tradi.png", alt: 'Traditional Gaming', goTo: "/traditional-gaming", id: 0 },
  { src: "/images/crypto.png", alt: 'Crypto Gaming', goTo: "/crypto-gaming", id: 1 },
  { src: "/images/mobil.png", alt: 'Mobile Gaming', goTo: "/mobile-gaming", id: 2 },
  { src: "/images/webBridge.png", alt: 'Web2/Web3 Bridge', goTo: "/web-bridge", id: 3 },
  { src: "/images/infl.png", alt: 'Influencers', goTo: "/influencers", id: 4 },
  { src: "/images/art.png", alt: 'Artists', goTo: "/artists", id: 5 }
]

export const ChoosePanel = () => {
  const { ref: secondRef, inView: myElementIsVisible } = useInView({ triggerOnce: true });
  const [, setHoveredIndex] = useState(-1);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(-1);
  };

  const handleMouseEnterInd = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeaveInd = () => {
    setActiveIndex(0);
  };
  return (
    <Container>
      <Row>
        <Col ref={secondRef} className={myElementIsVisible ? "choose-panel is-visible" : "choose-panel fade-in"}
             md={12}>
          <div className='d-flex choose-panel-li flex-column position-relative text-white'>
            <ListGroup>
              {chooseImage.map((item, i) => (
                <div key={item.alt} className='d-flex'>
                  <ListGroup.Item
                    key={item.id}
                    active={activeIndex === i}
                    onMouseEnter={() => handleMouseEnterInd(i)}
                    onMouseLeave={() => handleMouseLeaveInd()}
                    onMouseOver={() => handleMouseOver(i)}
                    onMouseOut={handleMouseOut}
                    style={activeIndex === i ?
                      { color: "#FF7704", textDecoration: "" } :
                      { color: "#fff", textDecoration: "none" }}
                    className='p-0 choose-panel-font'
                  >
                    <a key={item.id} className={i % 2 === 0 ? 'colorable mt-3' : 'mt-3 p-0 choose-panel-font py-2'}
                       style={activeIndex === i ?
                         { color: "#FF7704", textDecoration: "" } :
                         { color: "#fff", textDecoration: "none" }} href={item.goTo}>{item.alt.toUpperCase()}</a>
                    {activeIndex === i ? <img className="arrow" src="/images/arrow.svg" alt="Arrow"/> : ""}
                  </ListGroup.Item>
                  <div key={item.alt} className="choose-panel-image-wrapper">
                    {item.id === activeIndex ?
                      <img key={item.id} className="choose-panel-image animate__animated animate__fadeIn" alt={item.alt}
                           src={item.src}/>
                      : ""}
                  </div>
                </div>
              ))}
            </ListGroup>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
