import { Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Home } from "./pages/Home";
import { Footer } from "./components/Footer";
import { TraditionalGaming } from "./pages/TraditionalGaming";
import { CryptoGaming } from "./pages/CryptoGaming";
import { AboutUs } from "./pages/AboutUs";
import { MobileGaming } from "./pages/MobileGaming";
import { Web2 } from "./pages/Web2";
import { Influencers } from "./pages/Influencers";
import { Artists } from "./pages/Artists";

function App() {
  return (
    <>
    <Container className="slider-container">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/traditional-gaming" element={<TraditionalGaming />} />
        <Route path="/crypto-gaming" element={<CryptoGaming />} />
        <Route path="/mobile-gaming" element={<MobileGaming />} />
        <Route path="/web-bridge" element={<Web2 />} />
        <Route path="/influencers" element={<Influencers />} />
        <Route path="/artists" element={<Artists />} />
      </Routes>
    </Container>
    <Footer />
    </>
  );
}

export default App;
