import { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap"
import { useInView } from 'react-intersection-observer';

export const EndContent = () => {
  const { ref: thirdRef, inView: myElementIsVisible } = useInView({ triggerOnce: true });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const bookACall = () => {
    window.open("https://calendly.com/dglowicki/30-minutes-meeting-with-hololoot");
  }
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Container ref={thirdRef} className={myElementIsVisible ? "is-visible" : "fade-in"}>
      <Row className="justify-content-center align-items-center">
        <Col className="text-center" md={6}>
          <img className="human-img" src="/images/human-vr.png" alt="VR Human"/>
        </Col>
        <Col className="order" md={6}>
          <h2 className="mb-4">Say Goodbye To Limitations!</h2>
          {windowWidth >= 675 ?
            <>
              <p className="end-content-description">
                Unlock limitless opportunities with<br/>
                Augmented Reality campaigns.
              </p>
              <p className="end-content-description">
                Our solution offers endless possibilities for<br/>
                businesses of all sizes, with personalized,<br/>
                interactive experiences using geolocation-<br/>
                based points of interest.
              </p>
              <p className="end-content-description">
                Say goodbye to physical collateral, staffing,<br/>
                and permits. Join the revolution and<br/>
                captivate your customers today!
              </p>
            </> :
            <>
              <p className="end-content-description">
                Unlock limitless opportunities with
                Augmented Reality campaigns.
              </p>
              <p className="end-content-description">
                Our solution offers endless possibilities for
                businesses of all sizes, with personalized,
                interactive experiences using geolocation-
                based points of interest.
              </p>
              <p className="end-content-description">
                Say goodbye to physical collateral, staffing,
                and permits. Join the revolution and
                captivate your customers today!
              </p>
            </>}
          <button onClick={bookACall} type="button" className="btn btn-custom p-2 mt-4 fontadd">Book a call</button>
        </Col>
      </Row>
    </Container>
  )
}
