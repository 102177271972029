import { useState,useEffect } from 'react';
import { ListGroup, Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';
import { chooseImage } from './ChoosePanel';
import 'animate.css'

export const NavBar = () => {
  const [navDisplay,setNavDisplay] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [hideTimeout, setHideTimeout] = useState(null);
  const [, setHoveredIndex] = useState(-1);
  const path = window.location.pathname
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const bookACall = () => {
    window.open("https://calendly.com/dglowicki/30-minutes-meeting-with-hololoot");
  }

  const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(-1);
  };

  const handleMouseEnter = () => {
    clearTimeout(hideTimeout);
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    const timeoutId = setTimeout(() => setShowDropdown(false), 500);
    setHideTimeout(timeoutId);
  };

  const handleMouseEnterInd = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeaveInd = () => {
    setActiveIndex(0);
  };

  const changeColor = () => {
    if(window.scrollY > 100){
      setNavDisplay(true)
    } else {
      setNavDisplay(false)
    }
  }

  window.addEventListener('scroll', changeColor)
  return (
    <Navbar className={navDisplay || path === "/about-us" ? 'navbar-scroll' : 'navbar sml-dvc'}>
      <Container className='w-100'>
        <Navbar.Brand href="/">
        {windowWidth >= 600 ?
            <img
              width="130"
              height="50"
              className="d-inline-block align-top"
              src="/images/holo.svg"
              alt="Hololoot logo"
            />
            :
            <img
              width="59"
              height="56"
              className="d-inline-block align-top"
              src="/images/holo-logo.svg"
              alt="Hololoot logo"
            />
        }
        </Navbar.Brand>
          <Nav className="d-flex flex-row">
            <Nav.Link className={path === "/" ? 'mx-2 p-0 nav-active' : 'mx-2 p-0'} href="/">Home</Nav.Link>
            <Nav.Link className={
              path === "/about-us" ?
                'mx-2 p-0 nav-about-us-active'
                && navDisplay ?
                  "nav-active mx-2 p-0" :
                  'nav-about-us-active mx-2 p-0' :
                'mx-2 p-0'} href="/about-us"
            >
              About Us
            </Nav.Link>
            <NavDropdown show={showDropdown}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className='m-0 p-0'
                title="Offer">
              <div className='dropdown-items d-flex flex-column animate__animated animate__fadeIn'>
                <ListGroup>
                  {chooseImage.map((item, i) => (
                    <div key={item.alt} className='d-flex'>
                      <ListGroup.Item
                        key={item.id}
                        active={activeIndex === i}
                        onMouseEnter={() => handleMouseEnterInd(i)}
                        onMouseLeave={() => handleMouseLeaveInd()}
                        onMouseOver={() => handleMouseOver(i)}
                        onMouseOut={handleMouseOut}
                        style={activeIndex === i ?
                          { color: "#FF7704", textDecoration: "" } :
                          { color: "#fff", textDecoration: "none" }}
                        className='mx-3 p-0 dropdown-items'
                      >
                        <a key={item.id}
                           className={i % 2 === 0 ? 'mx-3 p-0 colorable dropdown-items' : ' mx-3 p-0 dropdown-items'}
                           style={activeIndex === i ?
                             { color: "#FF7704", textDecoration: "" } :
                             { color: "#fff", textDecoration: "none" }} href={item.goTo}>
                          {item.alt.toUpperCase()}
                        </a>
                      </ListGroup.Item>
                      <div key={item.alt} className="dropdown-image-wrap">
                        {item.id === activeIndex ?
                          <img key={item.id} className="dropdown-image animate__animated animate__fadeIn" alt={item.alt}
                               src={item.src}/>
                          : ""}
                      </div>
                    </div>))}
                </ListGroup>
              </div>
            </NavDropdown>
          </Nav>
        <button onClick={bookACall} type="button" className="btn btn-custom">Book a call</button>
      </Container>
    </Navbar>
  );
}
