import { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useInView } from 'react-intersection-observer';
import 'animate.css';
import { SubpageNavbar } from "../components/SubpageNavbar";

export const MobileGaming = () => {
  const { ref: firstRef, inView: MG1 } = useInView({ triggerOnce: true });
  const { ref: secondRef, inView: MG2 } = useInView({ triggerOnce: true });
  const { ref: thirdRef, inView: MG3 } = useInView({ triggerOnce: true });
  const { ref: fourthRef, inView: MG4 } = useInView({ triggerOnce: true });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const bookACall = () => {
    window.open("https://calendly.com/dglowicki/30-minutes-meeting-with-hololoot");
  }

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <SubpageNavbar/>
      <div className="mobile-gaming animate__animated animate__fadeIn">
        <span className="main-text">Mobile Gaming</span>
        <img className="arrows" src="/images/arrows-main.svg" alt="Arrow"/>
      </div>
      <div ref={firstRef} className={MG1 ? "mobile-gaming-I is-visible mt-1" : "mobile-gaming-I fade-in mt-1"}>
        <div className="MG-I-1">
          <h2 className="mb-4">Unleash The Power Of AR In Mobile Gaming</h2>
          <p className="mb-4">
            Are you looking to bring your mobile gaming<br/>
            experience to the next level?
          </p>
          {windowWidth >= 565 ?
            <p>
              Hololoot can help! With the power of AR, your<br/>
              players will be transported to new worlds,<br/>
              interacting with their favourite characters and<br/>
              unlocking unique rewards.
            </p> :
            <p>
              Hololoot can help! With the power of AR, your
              players will be transported to new worlds,
              interacting with their favourite characters and
              unlocking unique rewards.
            </p>
          }
        </div>
        <div className="MG-I-2">
          <img className="gaming-phone" src="/images/mobile-gaming-phone.png" alt="Gaming phone"/>
        </div>
      </div>
      <div ref={secondRef} className={MG2 ? "mobile-gaming-II is-visible" : "mobile-gaming-II fade-in"}>
        <div className="MG-II-1">
          {windowWidth >= 800 ?
            <div className="ar-car-bg">
              <video className="ar-car" autoPlay muted loop>
                <source src="/images/mobile-gaming-ar-car.webm" type="video/webm"/>
              </video>
            </div>
            :
            <div className="text-center">
              <img className="car-img" src="/images/mobile-gaming-car.png" alt="Car"/>
            </div>
          }
        </div>
        <div className="MG-II-2">
          <h2 className="mb-4">
            The Future Of Mobile Gaming<br/>
            Is Here
          </h2>
          {windowWidth >= 565 ?
            <>
              <p>
                Get ahead of the competition and<br/>
                enhance your player's experience<br/>
                with AR technology.
              </p>
              <p>
                Our app provides a secure<br/>
                platform for digital collectibles and<br/>
                creates a gaming environment that<br/>
                keeps your players engaged and<br/>
                coming back for more.
              </p>
            </> :
            <>
              <p>
                Get ahead of the competition and
                enhance your player's experience
                with AR technology.
              </p>
              <p>
                Our app provides a secure
                platform for digital collectibles and
                creates a gaming environment that
                keeps your players engaged and
                coming back for more.
              </p>
            </>}
        </div>
      </div>
      <div ref={thirdRef} className={MG3 ? "mobile-gaming-III is-visible" : "mobile-gaming-III fade-in"}>
        <div className="MG-III-1">
          <div className="woman-bg">
            <img className="woman-png" src="/images/mobile-gaming-demon-woman.png" alt="Demon woman"/>
          </div>
        </div>
        <div className="MG-III-2">
          <h2 className="mb-3">
            Solve Your<br/>
            Mobile Gaming Challenges
          </h2>
          {windowWidth >= 500 ?
            <p className="girl-description">
              From increasing player engagement<br/>
              to creating a cost-effective and<br/>
              scalable marketing strategy,<br/>
              Hololoot has the answers.
            </p>
            :
            <p className="girl-description">
              From increasing player engagement
              to creating a cost-effective and
              scalable marketing strategy,
              Hololoot has the answers.
            </p>
          }
        </div>
      </div>
      <Container ref={fourthRef} className={MG4 ? "is-visible" : "fade-in"}>
        <Row className="bottom-holo-info">
          <Col className="text-center mb-3">
            <img src="/images/holo-brown.svg" alt="LOGO"/>
          </Col>
          <Col className="text-center m-auto mb-5" md={12}>
            <h2 className="mb-4">
              Book A Call Today<br/>
              And Unleash<br/>
              The Potential Of AR
            </h2>
            {windowWidth >= 460 ?
              <p className="m-auto">
                Don't wait. Take the first step in revolutionizing<br/>
                your mobile gaming experience. Book a call<br/>
                with our team today and learn how we can<br/>
                help you unleash the power of AR.
              </p>
              :
              <p className="m-auto">
                Don't wait. Take the first step in revolutionizing
                your mobile gaming experience. Book a call
                with our team today and learn how we can
                help you unleash the power of AR.
              </p>
            }
          </Col>
          <Col className="text-center mb-5">
            <button onClick={bookACall} type="button" className="btn btn-brown">Book A Call</button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
