import React, {useState ,useEffect} from "react"
import { Carousel } from "react-bootstrap"

const images = [
    {src:"/images/home-slider/first-img-slider.png", id:1, alt: '' , description:"Elevating Marketing With Augmented Reality", color:"linear-gradient(90deg, rgba(18,29,64,1) 75%, rgba(255,255,255,1) 75%)", clr:"#121D40"},
    {src:"/images/home-slider/second-img-slider.png", id:2, alt: '' , description:"Say Goodbye To Limitations!", color:"linear-gradient(90deg, rgba(49,64,18,1) 75%, rgba(255,255,255,1) 75%)", clr:"#314012"},
    {src:"/images/home-slider/third-img-slider.png", id:3, alt: '' , description:"Unlock The Power Of Collectible Engagement", color:"linear-gradient(90deg, rgba(44,18,64,1) 75%, rgba(255,255,255,1) 75%)", clr:"#2C1240"},
    {src:"/images/home-slider/fourth-img-slider.png", id:4, alt: '' , description:"A Smartphone With A Camera Is All You Need", color:"linear-gradient(90deg, rgba(64,27,18,1) 75%, rgba(255,255,255,1) 75%)", clr:"#401B12"},
    {src:"/images/home-slider/fifth-img-slider.png", id:5, alt: '' , description:"Bring Your Marketing To Life", color:"linear-gradient(90deg, rgba(134,0,89,1) 75%, rgba(255,255,255,1) 75%)", clr:"#860059"}
]

export const Slider = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Carousel reverse="true" controls={false} indicators={false} activeIndex={index} onSelect={handleSelect}
                interval={4000}>
        {images.map((image) => (
          <Carousel.Item className="slider-description-holder" key={image.src}
                         style={windowWidth >= 600 ? { background: image.color } : { background: image.clr }}>
            <div className="slider-description">
              <h1>{image.description}</h1>
            </div>
            <button type="button" onClick={() => console.log(`Klik${image.id}`)}
                    className="btn btn-outline-warning slider-button-holder">Learn More</button>
          </Carousel.Item>))}
      </Carousel>
      <Carousel reverse="true" className="slider-image-holder" pause={false} controls={false} indicators={false}
                interval={4000} activeIndex={index} onSelect={handleSelect}>
        {images.map((image) => (
          <Carousel.Item key={image.src}>
            <img
              className="d-block w-100"
              src={image.src}
              alt={image.alt}
            />
          </Carousel.Item>))}
      </Carousel>
    </>
  );
};
