import { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useInView } from 'react-intersection-observer';
import 'animate.css';
import { SubpageNavbar } from "../components/SubpageNavbar";

export const TraditionalGaming = () => {
  const { ref: firstRef, inView: TG1 } = useInView({ triggerOnce: true });
  const { ref: secondRef, inView: TG2 } = useInView({ triggerOnce: true });
  const { ref: thirdRef, inView: TG3 } = useInView({ triggerOnce: true });
  const { ref: fourthRef, inView: TG4 } = useInView({ triggerOnce: true });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const bookACall = () => {
    window.open("https://calendly.com/dglowicki/30-minutes-meeting-with-hololoot");
  }

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <SubpageNavbar/>
      <div className="traditional-gaming animate__animated animate__fadeIn">
        <span className="main-text">Traditional Gaming</span>
        <img className="arrows" src="/images/arrows-main.svg" alt="Arrow"/>
      </div>
      <div ref={firstRef}
           className={TG1 ? "traditional-gaming-I is-visible mt-1" : "traditional-gaming-I fade-in mt-1"}>
        <div className="TG-I-1">
          <h2 className="mb-4">Revolutionize Your Gaming Experience <br/> With AR Collectibles</h2>
          {windowWidth >= 485 ?
            <>
              <p className="mb-4">
                At Hololoot, we believe in creating memorable<br/>
                and immersive gaming experiences for players.
              </p>
              <p>
                That's why we have merged the power of AR<br/>
                and digital collectibles to bring a new level<br/>
                of excitement to your game.
              </p> </> : <><p className="mb-4">
              At Hololoot, we believe in creating memorable
              and immersive gaming experiences for players.
            </p>
              <p>
                That's why we have merged the power of AR
                and digital collectibles to bring a new level
                of excitement to your game.
              </p></>}

        </div>
        <div className="TG-I-2">
          <img className="traditional-eq" src="/images/traditional-gaming-eq.png" alt="Sword,Helmet and Shield"/>
        </div>
      </div>
      <div ref={secondRef} className={TG2 ? "traditional-gaming-II is-visible mt-1" : "traditional-gaming-II fade-in "}>
        <div className="TG-II-1">
          <img className="joystick" src="/images/joystick.png" alt="Arrow & joystick"/>
        </div>
        <div className="TG-II-2">
          <h2 className="mb-4">
            Secure AR Collectibles<br/>
            For Your Players
          </h2>
          {windowWidth >= 485 ?
            <>
              <p className="mb-4">
                Our platform provides a safe and<br/>
                secure solution for gaming<br/>
                companies to offer AR collectibles<br/>
                to players.
              </p>
              <p>
                With our cutting-edge technology,<br/>
                players can now display their in-<br/>
                game items as if they were real,<br/>
                enhancing their gaming experience.</p>
            </> :
            <>
              <p className="mb-4">
                Our platform provides a safe and
                secure solution for gaming
                companies to offer AR collectibles
                to players.
              </p>
              <p>
                With our cutting-edge technology,
                players can now display their in-
                game items as if they were real,
                enhancing their gaming experience.</p>
            </>
          }
        </div>
        <div className="TG-II-3">
          {windowWidth >= 800 ?
            <video className="robot" autoPlay muted loop>
              <source src="/images/robot.webm" type="video/webm"/>
            </video>
            :
            <img className="robot-vid" src="/images/robot.svg" alt="Gaming Items"/>}
        </div>
      </div>
      <div ref={thirdRef} className={TG3 ? "traditional-gaming-III is-visible mt-1" : "traditional-gaming-III fade-in"}>
        <div className="TG-III-1">
          <img className="traditional-phone" src="/images/traditional-gaming-items.png" alt="Gaming Items"/>
        </div>
        <div className="TG-III-2">
          <h2 className="mb-4">
            Stand Out With Innovative<br/>
            In-Game Items
          </h2>
          {windowWidth >= 485 ?
            <>
              <p className="mb-4">
                Are you tired of offering the same<br/>
                unappealing in-game items?
              </p>
              <p>
                With Hololoot, you can differentiate<br/>
                your game by revolutionizing how<br/>
                players collect and display their<br/>
                items. Offer something unique and<br/>
                special to your players while<br/>
                promoting your brand.
              </p>
            </> :
            <>
              <p className="mb-4">
                Are you tired of offering the same
                unappealing in-game items?
              </p>
              <p>
                With Hololoot, you can differentiate
                your game by revolutionizing how
                players collect and display their
                items. Offer something unique and
                special to your players while
                promoting your brand.
              </p>
            </>
          }
        </div>
      </div>
      <Container ref={fourthRef} className={TG4 ? "is-visible" : "fade-in"}>
        <Row className="bottom-holo-info">
          <Col className="text-center mb-3">
            <img src="/images/holo-yellow.svg" alt="LOGO"/>
          </Col>
          <Col className="text-center m-auto mb-5 padi" md={12}>
            <h2>Ready To Take Your Crypto Gaming To The Next Level?</h2>
            {windowWidth >= 530 ? <>
                <p className="mt-3 m-auto">
                  Book a call today and join the AR revolution in<br/>
                  gaming! Our team of experts will help you<br/>
                  elevate your game and create unforgettable<br/>
                  experiences for your players.
                </p>
              </> :
              <>
                <p className="mt-3 m-auto">
                  Book a call today and join the AR revolution in
                  gaming! Our team of experts will help you
                  elevate your game and create unforgettable
                  experiences for your players.
                </p>
              </>
            }
          </Col>
          <Col className="text-center mb-5">
            <button onClick={bookACall} type="button" className="btn btn-yellow">Book A Call</button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
