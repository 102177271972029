import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Container, Row, Col } from "react-bootstrap";

import { SubpageNavbar } from "../components/SubpageNavbar";
import 'animate.css';

export const Web2 = () => {
  const { ref: firstRef, inView: WEB1 } = useInView({ triggerOnce: true });
  const { ref: secondRef, inView: WEB2 } = useInView({ triggerOnce: true });
  const { ref: thirdRef, inView: WEB3 } = useInView({ triggerOnce: true });
  const { ref: fourthRef, inView: WEB4 } = useInView({ triggerOnce: true });
  const { ref: fifth, inView: WEB5 } = useInView({ triggerOnce: true });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const bookACall = () => {
    window.open("https://calendly.com/dglowicki/30-minutes-meeting-with-hololoot");
  }

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <SubpageNavbar/>
      <div className="web2 animate__animated animate__fadeIn">
        <span className="main-text">Web2 Brands</span>
        <img className="arrows" src="/images/arrows-main.svg" alt="Arrow"/>
      </div>
      <div ref={firstRef} className={WEB1 ? "web-I is-visible mt-1" : "web-I fade-in mt-1"}>
        <div className="WEB-I-1">
          <h2 className="mb-4">Need Help To Stand Out?</h2>
          {windowWidth >= 500 ?
            <p>
              Hololoot has the solution. Our cutting-edge<br/>
              AR marketing can revolutionize customer<br/>
              engagement, and create a shopping<br/>
              experience like never before.
            </p>
            :
            <p>
              Hololoot has the solution. Our cutting-edge
              AR marketing can revolutionize customer
              engagement, and create a shopping
              experience like never before.
            </p>
          }
        </div>
        <div className="WEB-I-2">
          <img className="cat-img" src="/images/web2-holo-cat.png" alt="Cat"/>
        </div>
      </div>
      <div ref={secondRef} className={WEB2 ? "web-I-I is-visible" : "web-I-I fade-in"}>
        <div className="WEB-I-2">
          {windowWidth >= 800 ?
            <div className="holo-logo-ar2-bg">
              <video autoPlay muted loop className="holo-vr">
                <source src="/images/holo-logo-ar.webm" type="video/webm"/>
              </video>
            </div>
            :
            <div className="text-center">
              <img className="car-img" src="/images/web2-phone.png" alt="Gaming phone"/>
            </div>
          }
        </div>
        <div className="WEB-I-22">
          <h2 className="mb-4">Join The Future Of Marketing Today</h2>
          {windowWidth >= 450 ?
            <>
              <p>
                Discover the limitless possibilities of<br/>
                customer interaction with Hololoot.</p>
              <p>
                Our services are designed to help<br/>
                you navigate the new world of<br/>
                digital assets and drive success for<br/>
                your business.</p>
            </>
            :
            <>
              <p>
                Discover the limitless possibilities of
                customer interaction with Hololoot.</p>
              <p>
                Our services are designed to help
                you navigate the new world of
                digital assets and drive success for
                your business.</p>
            </>
          }
        </div>
      </div>
      <div ref={thirdRef} className={WEB3 ? "web-II is-visible" : "web-II fade-in"}>
        <div className="WEB-II-1">
          <h2 className="mb-4">Transform Traditional Marketing</h2>
          {windowWidth >= 420 ?
            <p>
              Say goodbye to mundane marketing<br/>
              strategies. Hololoot's AR brings your<br/>
              products to life, previewing them in<br/>
              3D and allowing customers to<br/>
              interact with them virtually. That<br/>
              enhances the shopping experience<br/>
              and increases the chances of a sale.
            </p>
            :
            <p>
              Say goodbye to mundane marketing
              strategies. Hololoot's AR brings your
              products to life, previewing them in
              3D and allowing customers to
              interact with them virtually. That
              enhances the shopping experience
              and increases the chances of a sale.
            </p>
          }
        </div>
        <div className="WEB-II-2">
          <img className="web2-screens" src="/images/web2-transform-marketing.png" alt="Marketing"/>
        </div>
      </div>
      <div ref={fourthRef} className={WEB4 ? "web-III is-visible" : "web-III fade-in"}>
        <div className="WEB-III-2">
          <img className="serpentin-png" src="/images/web-serpentin.png" alt="Serpentin"/>
        </div>
        <div className="WEB-III-1">
          <h2 className="mb-4">Experience The Full Potential Of Customer Engagement</h2>
          {windowWidth >= 475 ?
            <p className="mb-5">
              By combining AR technology with<br/>
              digital collectibles, you can offer<br/>
              a unique customer experience that<br/>
              sets you apart from competitors.<br/>
              Try Hololoot now and take your<br/>
              marketing to the next level!
            </p>
            :
            <p className="mb-5">
              By combining AR technology with
              digital collectibles, you can offer
              a unique customer experience that
              sets you apart from competitors.
              Try Hololoot now and take your
              marketing to the next level!
            </p>
          }
        </div>
      </div>
      <Container ref={fifth} className={WEB5 ? "is-visible" : "fade-in"}>
        <Row className="bottom-holo-info">
          <Col className="text-center mb-5">
            <img src="/images/holo-green.svg" alt="LOGO"/>
          </Col>
          <Col className="text-center m-auto mb-5" md={12}>
            <h2 className="mb-3">Book A Call Today</h2>
            {windowWidth >= 445 ?
              <>
                <p className="m-auto mb-4">
                  Want to learn more about how Hololoot can<br/>
                  help revolutionize your business?
                </p>
                <p className="m-auto">
                  Book a call with our experts today and<br/>
                  discover how you can unlock the power of<br/>
                  digital collectibles and immersive AR marketing.
                </p>
              </> :
              <>
                <p className="m-auto mb-4">
                  Want to learn more about how Hololoot can
                  help revolutionize your business?
                </p>
                <p className="m-auto">
                  Book a call with our experts today and
                  discover how you can unlock the power of
                  digital collectibles and immersive AR marketing.
                </p>
              </>
            }
          </Col>
          <Col className="text-center mb-5">
            <button onClick={bookACall} type="button" className="btn btn-green">Book A Call</button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
