import { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useInView } from 'react-intersection-observer';
import 'animate.css';
import { SubpageNavbar } from "../components/SubpageNavbar";

export const CryptoGaming = () => {
  const { ref: firstRef, inView: CG1 } = useInView({ triggerOnce: true });
  const { ref: secondRef, inView: CG2 } = useInView({ triggerOnce: true });
  const { ref: thirdRef, inView: CG3 } = useInView({ triggerOnce: true });
  const { ref: fourthRef, inView: CG4 } = useInView({ triggerOnce: true });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const bookACall = () => {
    window.open("https://calendly.com/dglowicki/30-minutes-meeting-with-hololoot");
  }

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <SubpageNavbar/>
      <div className="crypto-gaming animate__animated animate__fadeIn">
        <span className="main-text">Crypto Gaming</span>
        <img className="arrows" src="/images/arrows-main.svg" alt="Arrow"/>
      </div>
      <div ref={firstRef} className={CG1 ? "crypto-gaming-I is-visible mt-1" : "crypto-gaming-I fade-in mt-1"}>
        <div className="CG-I-1">
          <h2 className="mb-4">Unlock The Power Of AR In Crypto Gaming</h2>
          {windowWidth >= 1220 ?
            <p>
              Attract and engage gamers with cutting-edge<br/>
              AR solutions from Hololoot. Our services are<br/>
              explicitly designed to meet the challenges of<br/>
              the crypto gaming industry, delivering<br/>
              immersive, interactive experiences that keep<br/>
              players coming back for more.
            </p>
            :
            <p>
              Attract and engage gamers with cutting-edge
              AR solutions from Hololoot. Our services are
              explicitly designed to meet the challenges of
              the crypto gaming industry, delivering
              immersive, interactive experiences that keep
              players coming back for more.
            </p>
          }
        </div>
        <div className="CG-I-2">
          <img className="crypto-gaming-img" src="/images/crypto-gaming-batman.png" alt="Batman"/>
        </div>
      </div>
      <div ref={secondRef} className={CG2 ? "crypto-gaming-II is-visible" : "crypto-gaming-II fade-in"}>
        <div className="CG-II-1">
          <img className="crypto-gaming-img" src="/images/crypto-gaming-phone.png" alt="Collections on phone"/>
        </div>
        <div className="CG-II-2">
          <h2 className="mb-4">Turn Players Into Fans</h2>
          {windowWidth >= 1115 ?
            <p>
              With Hololoot, you'll turn players<br/>
              into collectors and fans who<br/>
              treasure and promote your brand.<br/>
              Our app provides a secure and<br/>
              fraud-resistant platform for digital<br/>
              collectibles, giving your business<br/>
              peace of mind and a valuable<br/>
              marketing tool.
            </p>
            :
            <p>
              With Hololoot, you'll turn players
              into collectors and fans who
              treasure and promote your brand.
              Our app provides a secure and
              fraud-resistant platform for digital
              collectibles, giving your business
              peace of mind and a valuable
              marketing tool.
            </p>
          }
        </div>
      </div>
      <div ref={thirdRef} className={CG3 ? "crypto-gaming-III is-visible" : "crypto-gaming-III fade-in"}>
        <div className="CG-III-1">
          <h2 className="mb-4">
            Say Goodbye To<br/>
            Stale Marketing Strategies
          </h2>
          {windowWidth >= 400 ?
            <>
              <p>
                In a crowded market, it's tough to<br/>
                stand out. But with Hololoot, you'll<br/>
                never have to worry about bland,<br/>
                unengaging marketing again.<br/>
              </p>
              <p>
                Our innovative platform combines the<br/>
                latest digital technology with real-<br></br>
                world experiences, providing<br/>
                a unique and unforgettable way to<br/>
                promote your brand.
              </p>
            </> :
            <p>
              In a crowded market, it's tough to
              stand out. But with Hololoot, you'll
              never have to worry about bland,
              unengaging marketing again. Our
              innovative platform combines the
              latest digital technology with real-
              world experiences, providing
              a unique and unforgettable way to
              promote your brand.
            </p>
          }
        </div>
        <div className="CG-III-2">
          <img className="crypto-gaming-thunder" src="/images/crypto-gaming-collection.png" alt="Gaming Items"/>
        </div>
      </div>
      <Container ref={fourthRef} className={CG4 ? "is-visible" : "fade-in"}>
        <Row className="bottom-holo-info">
          <Col className="text-center mb-3">
            <img src="/images/holo-purple.svg" alt="LOGO"/>
          </Col>
          <Col className="text-center m-auto mb-5" md={12}>
            <h2 className="mb-4">
              Ready To Take Your Crypto Gaming<br/>
              To The Next Level?
            </h2>
            {windowWidth >= 480 ?
              <p>
                Book a call with one of our experts today and<br/>
                discover how Hololoot's AR marketing solutions<br/>
                can help you reach new heights and engage<br/>
                your audience like never before!
              </p>
              :
              <p>
                Book a call with one of our experts today and
                discover how Hololoot's AR marketing solutions
                can help you reach new heights and engage
                your audience like never before!
              </p>
            }
          </Col>
          <Col className="text-center mb-5">
            <button onClick={bookACall} type="button" className="btn btn-purple">Book A Call</button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
