import { Row, Col } from "react-bootstrap";
import { useInView } from 'react-intersection-observer';
import { NavBar } from "../components/Navbar";

const teamMembers = [
    {src:"/images/team-1.svg", name: 'Adam Białoń', wud: 'CEO & Founder', details: 'Adam has more than eight years of experience as an entrepreneur helping customers and businesses to understand and use Immersive technology.',id:1 , link:"https://pl.linkedin.com/in/adam-bialon"},
    {src:"/images/team-2.svg", name: 'Dominik Głowicki', wud: 'CGO', details: 'Dominik is a professional with over six years of experience creating and successfully executing growth strategies based on various acquisition channels.',id:2, link:"https://pl.linkedin.com/in/dominikglowicki"},
    {src:"/images/team-3.svg", name: 'Łukasz Wierzbicki', wud: 'CTO', details: 'Łukasz has over 15 years of experience building scalable backend solutions on top of Java tech stack, leading and transforming software development teams.',id:3, link:"https://pl.linkedin.com/in/wierzbickilukasz"},
    {src:"/images/team-4.svg", name: 'Artur Guliński', wud: 'Marketing Manager', details: 'Experienced and energetic marketing manager with ten years of experience effectively managing marketing projects from conception to completion.',id:4, link: "https://pl.linkedin.com/in/artur-gulinski/de?trk=org-employees"},
    {src:"/images/team-5.svg", name: 'Adam Bąk', wud: 'Head of Studio', details: 'Adam is an experienced Head Of the Studio and Project Manager. Most recently focused on 3D assets and modeling.',id:5, link: "https://pl.linkedin.com/in/adam-b%C4%85k-a92885167"},
    {src:"/images/team-6.svg", name: 'Mike Lorek', wud: 'Creative Director', details: 'Experienced Project Manager and Designer focusing on digital assets creation. Mike is responsible for marketing assets, art direction, and AR video creation.',id:6 , link:"https://es.linkedin.com/in/mi-lor"},
    {src:"/images/team-7.svg", name: 'Marek Szymczakowski', wud: 'Business Advisor', details: 'Marek Szymczakowski has a strong background in investment, having worked with private equity firms and venture capital companies.',id:7, link:"https://www.linkedin.com/in/marek-szymczakowski/?originalSubdomain=pl"},
]

export const AboutUs = () => {
  const { ref: firstRef, inView: ABOUT1 } = useInView({ triggerOnce: true });
  const { ref: secondRef, inView: ABOUT2 } = useInView({ triggerOnce: true });
  const { ref: thirdRef, inView: ABOUT3 } = useInView({ triggerOnce: true });
  const { ref: fourthRef, inView: ABOUT4 } = useInView({ triggerOnce: true });
  const { ref: fifthRef, inView: ABOUT5 } = useInView({ triggerOnce: true });

  const bookACall = () => {
    window.open("https://calendly.com/dglowicki/30-minutes-meeting-with-hololoot");
  }

  return (
    <>
      <NavBar className="postion-relative sml-dvc "/>
      <div className="slider-about">
        <div className="about-us-description">
          <h1>About Us</h1>
          <img className="arrows-about" src="/images/arrows-main.svg" alt="Arrow"/>
        </div>
        <div className="about-us-image-holder">
          <img className="about-us-logo" src="/images/about/about-us-logo.svg" alt="Logo"/></div>
      </div>
      <div ref={firstRef} className={ABOUT1 ? "about-us-I is-visible mt-1" : "about-us-I fade-in mt-1"}>
        <h2 className="mb-5">Welcome to Hololoot</h2>
        <p>We provide B2B Augmented Reality marketing solutions that transform your customers into passionate fans. Our
          innovative app is created by a team of experienced developers, business-minded entrepreneurs, talented
          creative director, studio head and 3D artists who share a passion for marketing and technology.</p>
        <p> Together, we offer flexible solutions that cater to your specific needs and goals, creating bespoke
          campaigns that engage customers and deliver exceptional results.</p>

      </div>
      <h1 className="text-center">Core Team</h1>
      <Row ref={secondRef}
           className={ABOUT2 ? "slider-row justify-content-md-center text-center w-100 team-visible" : "slider-row justify-content-md-center text-center w-100 fade-in"}>
        {teamMembers.map((member) => (
          <Col key={member.id} className="text-center pb-5" md={4}>
            <a href={member.link}>
              <img width="301" height="301" src={member.src} alt=""/>
            </a>
            <p className="bold-text mb-0 mt-2">{member.name}</p>
            <p className="bold-text" style={{ color: "#FF7704" }}>{member.wud}</p>
            <p className="member-details">{member.details}</p>
          </Col>
        ))}
      </Row>
      <div ref={thirdRef} className={ABOUT3 ? "about-II is-visible mt-1" : "about-II fade-in mt-1"}>
        <div className="ABOUT-II-1">
          <h2 className="mb-4">Bring Your Marketing to Life with Hololoot</h2>
          <p>Whether you're a small business looking to make a significant impact or a large company seeking the next
            frontier in marketing, Hololoot has the solution.</p>
          <p> Contact us today to learn more about our AR marketing services and how we can help you create a memorable
            experience for your customers.</p>
        </div>
        <div className="ABOUT-II-2">
          <img className="robot-img" src="/images/about/about-robot.png" alt="Cat"/>
        </div>
      </div>
      <div ref={fourthRef} className={ABOUT4 ? "about-III is-visible" : "about-III fade-in"}>
        <div className="ABOUT-III-1">
          <img className="phone-about-img" src="/images/about/phone-about.png" alt="Phone"/>
        </div>
        <div className="ABOUT-III-2">
          <h2 className="mb-4">A Smartphone<br/> With A Camera <br/> Is All You Need br na mobil</h2>
          <p>Hololoot provides businesses of all sizes access to innovative AR marketing campaigns that deliver
            exceptional results. Our app combines the possibilities of AR with digital collectibles, offering customers
            a memorable experience they can keep forever.</p>
        </div>
      </div>
      <div ref={fifthRef} className={ABOUT5 ? "about-us-book is-visible" : "about-us-book fade-in"}>
        <div className="ABT-BOOK-1">
          <img className="girl-glasses" src="/images/about/girl-glasses.png" alt="Girl glasses"/>
        </div>
        <div className="ABT-BOOK-2">
          <h3 className="mb-3 pt-5">Unlock The Power Of Collectible Engagement<br/> With Hololoot!</h3>
          <p className="about-p-book mb-3">Transform your customers into enthusiastic collectors with the unique
            combination of AR and digital collectibles. Hololoot partners with some of the hottest projects, like
            CryptoBlades, to create engaging campaigns and contests. With our app, players can collect AR assets
            modelled after their favourite in-game items and show them off as real.</p>
          <p> Hololoot provides a safe and trustworthy platform for digital collectibles, giving you peace of mind and a
            powerful marketing tool. Take advantage of this opportunity to ignite excitement and drive engagement with
            your audience. Join the Hololoot revolution today!</p>
          <button onClick={bookACall} type="button" className="btn btn-orange my-4">Book A Call</button>
        </div>
      </div>
    </>)
};
