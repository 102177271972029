import { useState,useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useInView } from 'react-intersection-observer';

import { Slider } from "../components/Slider";
import { EndContent } from "../components/EndContent"
import { NavBar } from "../components/Navbar";
import { ChoosePanel } from "../components/ChoosePanel";

export const Home = () => {
  const { ref: firstRef , inView: myElementIsVisible } = useInView({ triggerOnce: true });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <NavBar className="postion-relative"/>
      <Slider/>
      <Container className="d-flex flex-column w-100">
        <Row ref={firstRef}
             className={myElementIsVisible ? "justify-content-center is-visible mt-1" : "justify-content-center fade-in mt-1"}>
          <Col md={8} className="description-wrapper">
            <h2 className="mb-4">Effective AR Marketing</h2>
            {windowWidth >= 800 ?
              <>
                <p className="mb-4">
                  Today's marketing landscape values engagement,<br></br>
                  and AR offers the perfect solution. Research shows<br></br>
                  that customers are 94% more likely to purchase<br></br>
                  a product if they see it in AR or 3D.
                </p>
                <p>
                  Hololoot makes it easy and affordable for businesses<br></br>
                  to create immersive and interactive campaigns that<br></br>
                  engage customers and deliver results.
                </p>
              </> :
              <>
                <p className="mb-4">
                  Today's marketing landscape values engagement,
                  and AR, offers the perfect solution. Research shows
                  that customers are 94% more likely to purchase
                  a product if they see it in AR <br></br>or 3D.
                </p>
                <p>
                  Hololoot makes it easy and affordable for businesses
                  to create immersive and interactive campaigns that
                  engage customers and deliver results.
                </p>
              </>}
          </Col>
          <Col className="text-center" md={12} lg={8}>
            <img className="home-img" src="/images/ar-marketing.png" alt="Store"/>
          </Col>
        </Row>
      </Container>
      <ChoosePanel/>
      <EndContent/>
    </>
  )
};
